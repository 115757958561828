import React, { useState, useEffect, useRef, createContext, useMemo, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import GridPanel from "../gridPanel"
import ReportPanel from '../reportPanel';
import FormsPanel from '../formsPanel';
import PivotsPanel from '../pivotsPanel';
import MapPanel from '../Maps/mapPanel';
import CardsPanel from '../cardsPanel';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';


const NewPanelsContent = ({ panel, panelJSON, screenList, d, dynamicGridHeight }) => {
    const client = useApolloClient();
    const default_master_guid = useSelector(state => state.mainReducer.default_master_guid);

    const isValidDate = (replacement) => {
        // Try to parse the string with Moment
        const parsedDate = moment(replacement, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(]Z[)]', true);

        // Check if the parsing was successful and the date is valid
        return parsedDate.isValid();
    };

    const card = () => {

        switch (panelJSON && panelJSON.element_type) {
            case 'grid':
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    var gridQuery = panelJSON.query
                    let rowCountQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (replacement === null || replacement.length < 1) {
                            gridQuery = "{}"
                            rowCountQuery = "{}"
                            break;
                        }
                        else {
                            if (isValidDate(replacement)) {
                                replacement = moment(replacement, 'yyyy-MM-dd', true).format()
                                replacement = replacement.slice(0, -6)
                            }
                            replacement = '\"' + replacement + '\"'

                        }


                        gridQuery = gridQuery.replace(`@${i}`, replacement)
                        rowCountQuery = rowCountQuery.replace(`@${i}`, replacement)
                    }
                    gridQuery = gridQuery.substring(1, gridQuery.length - 1)
                    rowCountQuery = rowCountQuery.substring(1, rowCountQuery.length - 1)
                    return <GridPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} gridQuery={gridQuery} dynamicGridHeight={dynamicGridHeight} rowCountQuery={rowCountQuery} />
                }
                else {
                    var gridQuery = panelJSON.query
                    let rowCountQuery = panelJSON.row_count
                    let replacement;
                    for (let i of screenList) {
                        if (i.required && i.param_value === null) {
                            gridQuery = "{}"
                            rowCountQuery = "{}"
                            break;
                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {
                            replacement = i.param_value
                        }
                        gridQuery = gridQuery.replace(`@${i.param}`, replacement)
                        rowCountQuery = rowCountQuery.replace(`@${i.param}`, replacement)
                    }
                    gridQuery = gridQuery.substring(1, gridQuery.length - 1)
                    rowCountQuery = rowCountQuery.substring(1, rowCountQuery.length - 1)
                    return <GridPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} gridQuery={gridQuery} dynamicGridHeight={dynamicGridHeight} rowCountQuery={rowCountQuery} />
                }
            case 'report':
                let reportQuery = panelJSON.query.replace('@v_locale', 'en')
                reportQuery = reportQuery.substring(1, reportQuery.length - 1)
                return <ReportPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} reportQuery={reportQuery} />
            case 'form':
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    var formsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        formsQuery = formsQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    formsQuery = formsQuery.substring(1, formsQuery.length - 1)
                    return <FormsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} formsQuery={formsQuery} queryName={queryName} />
                }
                else {
                    var formsQuery = panelJSON.query
                    let animalsId;
                    let objectWithGuid;
                    let replacement;
                    for (let i of screenList) {
                        if (i.param.includes("guid")) {
                            if (i.panel_parameters_mapping[0].sys_columns_guid !== null) {
                                let copyOfI = JSON.parse(JSON.stringify(i))
                                const defaultMasterGuidObject = default_master_guid && Array.isArray(default_master_guid) ? default_master_guid.find(item => item.param === copyOfI.param) : null;
                                copyOfI.param_value = defaultMasterGuidObject?.param_value
                                animalsId = copyOfI
                            }
                            else {
                                objectWithGuid = i
                            }

                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {

                            replacement = i.param_value
                        }
                        formsQuery = formsQuery.replace(`@${i.param}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    formsQuery = formsQuery.substring(1, formsQuery.length - 1)
                    return <FormsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} animalsId={animalsId} objectWithGuid={objectWithGuid} formsQuery={formsQuery} queryName={queryName} />
                }
            case "pivot":
                let pivotsQuery = panelJSON.query
                for (let i of screenList) {
                    let replacement = '\"' + i.param_value + '\"'
                    pivotsQuery = pivotsQuery.replace(`@${i.param}`, replacement)
                }
                let queryName = panelJSON.query_name
                pivotsQuery = pivotsQuery.substring(1, pivotsQuery.length - 1)
                return <PivotsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} pivotsQuery={pivotsQuery} queryName={queryName} dynamicGridHeight={dynamicGridHeight} />
            case "map": {
                let mapsQuery = panelJSON.query
                if (screenList) {
                    for (let i of screenList) {
                        let replacement = '\"' + i.param_value + '\"'
                        mapsQuery = mapsQuery.replace(`@${i.param}`, replacement)
                    }
                }
                else {
                    mapsQuery = mapsQuery.replace('@v_locale', '"en"')
                }
                let queryName = panelJSON.query_name
                mapsQuery = mapsQuery.substring(1, mapsQuery.length - 1)
                return <MapPanel mapType={panel.isOpenStreet} panel={panel} panelJSON={panelJSON} mapsQuery={mapsQuery} queryName={queryName} />
            }
            case "card":
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    let cardsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        cardsQuery = cardsQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    cardsQuery = cardsQuery.substring(1, cardsQuery.length - 1)
                    return <CardsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsQuery={cardsQuery} queryName={queryName} />
                }
                else {
                    let cardsQuery = panelJSON.query
                    for (let i of screenList) {
                        let replacement = '\"' + i.param_value + '\"'
                        cardsQuery = cardsQuery.replace(`@${i.param}`, replacement)
                    }
                    cardsQuery = cardsQuery.substring(1, cardsQuery.length - 1)
                    return <CardsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsQuery={cardsQuery} queryName={queryName} />
                }

            default:
                return null
        }
    }


    return (
        <>
            {card()}
        </>
    );
}

export default NewPanelsContent