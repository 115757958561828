import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { markerClusterGroup } from 'leaflet.markercluster';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { SYS_UPDATE_PANEL_VIEW_MAP, SYS_ADD_PANEL_VIEW_MAP } from '../../../../apollo/tasks';
import { getTaskStatus } from '../../../../apollo/queries';
import { useApolloClient } from '@apollo/react-hooks';

export const useInitializeMap = (panel, panelJSON, isDrawPolygon, dispatch, controls, animalsData, polygonsForMapList, isTreeViewToggled, toastRef) => {
    const mapRef = useRef(null);
    const client = useApolloClient();
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);
    const [center, setCenter] = useState(panelJSON?.map_definition?.centroid?.coordinates ? [panelJSON.map_definition.centroid.coordinates[1], panelJSON.map_definition.centroid.coordinates[0]] : [51.505, -0.09]);
    const [zoom, setZoom] = useState(panelJSON?.map_definition?.zoom || 13);
    const isManageSaveButtonCLicked = useSelector(state => state.manageViewsReducer.isManageSaveButtonCLicked);
    const isManageVewsSaveAsDialogSaveButtonClicked = useSelector(state => state.manageViewsReducer.isManageVewsSaveAsDialogSaveButtonClicked);
    const panelsViewGuid = useSelector((state) => state.mainReducer.panelsViewGuid);
    const manageViewsViewName = useSelector((state) => state.manageViewsReducer.manageViewsViewName);

    const showToast = (message, type) => {
        if (toastRef.current) {
            toastRef.current.show({
                content: message || '',
                cssClass: `e-toast-${type} custom-toast-position`,
                position: { X: 'Right', YOffset: '1000' },
                animation: { show: { duration: 500 }, hide: { duration: 500 } },
                timeOut: 3000,
            });
        }
    }

    useEffect(() => {
        setCenter(panelJSON?.map_definition?.centroid?.coordinates ? [panelJSON.map_definition.centroid.coordinates[1], panelJSON.map_definition.centroid.coordinates[0]] : [51.505, -0.09]);
        setZoom(panelJSON?.map_definition?.zoom || 13);
    }, [panelJSON]);

    useEffect(() => {
        const map = L.map(mapRef.current).setView(center, zoom);
        map.on('moveend', () => {
            setCenter(map.getCenter());
            setZoom(map.getZoom());
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
        }).addTo(map);

        if (isDrawPolygon) {
            enablePolygonDrawing(map, dispatch);
        }

        const markers = L.markerClusterGroup();
        const polygons = L.layerGroup();

        addAnimalMarkersToMap(markers, controls, animalsData, panelJSON);
        addPolygonsToMap(map, polygons, polygonsForMapList);
        if (isManageSaveButtonCLicked) {
            saveMapView(map);
            dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_BUTTON', payload: false });
        }

        if (isManageVewsSaveAsDialogSaveButtonClicked) {
            saveAsMapView(map)
            dispatch({ type: 'CLICK_MANAGE_VIEWS_SAVE_AS_DIALOG_SAVE_BUTTON', payload: false });
        }

        map.addLayer(markers);
        map.addLayer(polygons);

        return () => {
            map.remove();
        };
    }, [isDrawPolygon, dispatch, controls, animalsData, panelJSON, polygonsForMapList, isTreeViewToggled, isManageSaveButtonCLicked, isManageVewsSaveAsDialogSaveButtonClicked, isRefreshButtonClicked]);

    const convertLatLngToArray = (latLng) => {
        return `POINT(${latLng.lng} ${latLng.lat})`
    }


    const saveMapView = async (map) => {
        const centroid = convertLatLngToArray(map.getCenter());
        const { data } = await client.query({
            query: SYS_UPDATE_PANEL_VIEW_MAP,
            variables: {
                v_locale: "en",
                v_sys_panels_views_guid: panelsViewGuid,
                v_centroid: centroid,
                v_zoom: parseInt(map.getZoom()),
                v_sys_dynamic_datasources_guid: null,
                v_image: null,
                v_real_size: null
            }
        })
        if (data.dal_add_task) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    }

    const saveAsMapView = async (map) => {
        const centroid = convertLatLngToArray(map.getCenter());
        const { data } = await client.query({
            query: SYS_ADD_PANEL_VIEW_MAP,
            variables: {
                v_locale: "en",
                v_sys_panels_guid: panel.sys_panels_guid,
                v_sys_owners_guid: "43f2b87a-2946-4a8c-bdbc-78c82341d2ab",
                v_view_name: manageViewsViewName,
                v_centroid: centroid,
                v_zoom: map.getZoom(),
                v_sys_dynamic_datasources_guid: null,
                v_image: null,
                v_real_size: null
            }
        })
        if (data.dal_add_task) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    }

    const enablePolygonDrawing = (map, dispatch) => {
        map.pm.enableDraw('Polygon', {
            snappable: true,
            snapDistance: 20,
        });
        map.pm.addControls({
            position: 'topleft',
            drawMarker: false,
            drawCircleMarker: false,
            drawPolyline: false,
            drawCircle: false,
            drawText: false,
            editMode: false,
            dragMode: false,
            cutPolygon: false,
            removalMode: false,
            rotateMode: false,
        });
        map.on('pm:create', e => {
            const polygon = e.layer;
            if (polygon) {
                dispatch({ type: 'TOGGLE_MAP_DIALOG', payload: true });
                dispatch({ type: 'SAVE_POLYGON_LAT_LNGS', payload: polygon._latlngs[0] });
            }
        });
    };

    const addAnimalMarkersToMap = (markers, controls, animalsData, panelJSON) => {
        animalsData?.forEach((animal) => {
            panelJSON?.map_definition?.pins?.forEach((pin) => {
                const pinFound = findObjectBySysColumnsGuid(controls, pin.pin.sys_columns_guid);
                if (pinFound && animal[pinFound.name] !== null) {
                    const columnNameToOrder = {};
                    controls.forEach(column => {
                        columnNameToOrder[column.name] = column.column_order;
                    });

                    // Step 2: Sort object keys based on column_order
                    const sortedKeys = Object.keys(animal).sort((a, b) => {
                        const orderA = columnNameToOrder[a] || Infinity; // If column_order is not defined, treat as Infinity
                        const orderB = columnNameToOrder[b] || Infinity;
                        return orderA - orderB;
                    });

                    // Step 3: Construct sorted object
                    const sortedObject = {};
                    sortedKeys.forEach(key => {
                        sortedObject[key] = animal[key];
                    });
                    // Parse the coordinates from the new format
                    const coordinatesString = animal[pinFound.name].match(/POINT\s*\(\s*([-\d.]+)\s+([-\d.]+)\s*\)/);
                    if (coordinatesString) {
                        const lon = parseFloat(coordinatesString[1]);
                        const lat = parseFloat(coordinatesString[2]);

                        const icon = createCustomPinIcon(pin.pin.colour);
                        const marker = L.marker(
                            [lat, lon],
                            { icon }
                        ).bindPopup(getPopupContent(sortedObject));
                        markers.addLayer(marker);
                    } else {
                        console.error('Invalid geometry format for animal:', animal);
                    }
                }
            });
        });
    };


    const addPolygonsToMap = (map, polygons, polygonsForMapList) => {
        polygonsForMapList?.dal_get_polygons_for_map_list?.forEach((region) => {
            const regionCoords = region?.regions_definition?.coordinates.map(processCoordinates);
            const polygon = L.polygon(regionCoords, { color: 'blue', fillOpacity: 0.5 });
            polygons.addLayer(polygon);
            if (!isDrawPolygon) {
                polygon.on('click', () => {
                    L.popup()
                        .setLatLng(polygon.getBounds().getCenter())
                        .setContent(`Polygon Name: ${region.regions_name}`) // Ensure 'name' is the correct property
                        .openOn(map);
                });
            } else {
                polygon.off('click');
            }
        });
    };

    const createCustomPinIcon = (colour) => {
        const markerHtmlStyles = `
            background-color: rgb${colour};
            width: 24px;
            height: 24px;
            display: block;
            left: -8px;
            top: -8px;
            position: relative;
            border-radius: 24px 24px 0;
            transform: rotate(45deg);
            border: 1px solid #FFFFFF`;
        return L.divIcon({
            className: "my-custom-pin",
            iconAnchor: [0, 24],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`
        });
    };

    const findObjectBySysColumnsGuid = (array, sysColumnsGuid) => {
        return array.find(item => item.sys_columns_guid === sysColumnsGuid);
    };

    const getPopupContent = (animal) => {
        return Object.entries(animal)
            .filter(([key, value]) => value && key !== '__typename')
            .map(([key, value]) => `<b>${key}:</b> ${value}<br>`)
            .join('');
    };

    const processCoordinates = (coords) => {
        if (Array.isArray(coords)) {
            if (typeof coords[0] === 'number' || typeof coords[0] === 'string') {
                return [coords[1], coords[0]];
            }
            return coords.map(processCoordinates);
        }
    };

    return mapRef;
};
