import { useRef, useState, useEffect } from 'react';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useNavigate, useParams } from 'react-router';
import { SelectedRowContext } from '../../../context/SelectedRowContext';
import { GlobalTheme } from '../../../context/GlobalTheme';
import { ActionButtonContext } from '../../../context/ActionButtonContext';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { gql } from "@apollo/client";
import { SET_SYS_SCREENS_PANELS_VIEW_AS_USER_FAVOURITE } from '../../../apollo/tasks';
import { GET_SCREENS_PANELS_ACTIONS } from '../../../apollo/queries';
import { useDispatch, useSelector } from 'react-redux';
import TreeViewContent from './treeViewPopover';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import ManageViewsSaveAsDialogContent from './components/manageViewsSaveAsDialogContent';

const PanelsContent = ({ panel, draggableHandleClass, screenList }) => {
    const client = useApolloClient();
    const user = JSON.parse(localStorage.getItem('user'));
    const toastRef = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams()
    const { selectedRow } = useContext(SelectedRowContext);
    const { changeActionButton, actionButton } = useContext(ActionButtonContext);
    const globalTheme = useContext(GlobalTheme)
    const [foundManageViewsCanEdit, setFoundManageViewsCanEdit] = useState(null);
    const [menuItemCSSId, setMenuItemCSSId] = useState(null);
    const [actionButtonsArray, setActionButtonsArray] = useState([]);
    const [selectedPanelView, setSelectedPanelView] = useState(null)
    const dispatch = useDispatch();

    const anchorEl = useSelector(state => state.treeViewReducer.anchorEl);
    const isManageViewsSaveAsDialogOpen = useSelector(state => state.manageViewsReducer.isManageViewsSaveAsDialogOpen);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);

    // const findBySysPanelsViewsGuid = (array, guid) => {
    //     let foundPanelsViewsObject = array.find(item => item.sys_panels_views_guid === guid);
    //     setFoundManageViewsCanEdit(foundPanelsViewsObject.can_edit)
    // };

    // const _getPanelViewQuery = async () => {
    //     const panelQueryReplaced = panel.panel_view_query.replace('@locale', '"en"').replace('@sys_panels_guid', `"${panel.sys_screens_panels_guid}"`).slice(1, -1)
    //     const { data } = await client.query({ query: gql(`${panelQueryReplaced}`) })
    //     setPanelViewList(data.dal_get_sys_panels_views_by_panel_list)
    //     const hasDefaultView = data.dal_get_sys_panels_views_by_panel_list.find(item => item.is_favourite);
    //     if (hasDefaultView) {
    //         setValue(hasDefaultView.sys_panels_views_guid)
    //         findBySysPanelsViewsGuid(data.dal_get_sys_panels_views_by_panel_list, hasDefaultView.sys_panels_views_guid)
    //         setText(hasDefaultView.view_name)
    //         dispatch({ type: 'SET_PANELS_VIEWS_GUID', data: hasDefaultView.sys_panels_views_guid })
    //         dispatch({ type: 'SET_PANELS_GUID', data: panel.sys_panels_guid })
    //     }
    // }

    const _getScreensPanelsActions = async () => {
        const { data } = await client.query({ query: GET_SCREENS_PANELS_ACTIONS, variables: {
            v_sys_screens_panels_guid: panel.sys_screens_panels_guid,
            v_tenants_guid: user.tenant.tenants_guid,
            v_users_guid: user.user_uid,
            v_sys_actions_flags_guid: panel.sys_actions_flags_guid,
        } })
        setActionButtonsArray(data.dal_get_sys_screens_panels_actions?.[0]?.gql_results?.buttons)
    }

    useEffect(() => {
        _getScreensPanelsActions()
        return () => {
            dispatch({ type: 'TOGGLE_TREEVIEW_POPOVER', payload: null })
        };
    }, [])

    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.id = 'dynamic-style';

        // Remove any previous dynamic styles
        const existingStyle = document.getElementById('dynamic-style');
        if (existingStyle) {
            existingStyle.remove();
        }

        if (menuItemCSSId) {
            styleElement.innerHTML = `
            #menu-item-${menuItemCSSId} {
              background: ${globalTheme[globalTheme.activeTheme]?.hover};
            }
          `;
            document.head.appendChild(styleElement);
        }
        else {
            styleElement.innerHTML = `
            .favourite {
              background: ${globalTheme[globalTheme.activeTheme]?.hover};
            }
          `;
            document.head.appendChild(styleElement);
        }

        return () => {
            if (styleElement.parentNode) {
                styleElement.parentNode.removeChild(styleElement);
            }
        };
    }, [menuItemCSSId]);

    const goToAddPage = (b) => {
        if (b?.action_value === "") {
            return "";
        }
        navigate(`/${b?.action_value}`, { state: { param_value: b } });
    }

    const goToEditPage = (b, selectedRow) => {
        if (selectedRow) {
            let parameterId = JSON.parse(JSON.stringify(b))
            parameterId.action_parameters.forEach(param => {
                let selectedId = param.param_value.replace(/@/g, '');
                param.param_value = selectedRow[selectedId];
            });
            navigate(`/${b?.action_value}`, { state: { param_value: parameterId } });
        }

    }

    const setFavouriteUserView = async () => {
        const { data } = await client.query({
            query: SET_SYS_SCREENS_PANELS_VIEW_AS_USER_FAVOURITE, variables: {
                v_sys_screens_panels_guid: panel.sys_screens_panels_guid,
                v_sys_panels_views_guid: selectedPanelView
            }
        })
    }

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const openManageViewsSaveAsDialog = () => {
        dispatch({ type: 'SAVE_MANAGE_VIEWS_PANELS_GUID', payload: panel.sys_panels_guid })
        dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_AS_DIALOG', payload: true })
    }

    const manageViewsSaveAsDialogContent = () => {
        return <ManageViewsSaveAsDialogContent />
    }

    const [selectedItem, setSelectedItem] = useState(null);

    const getActionButton = (args) => {
        setSelectedItem(args.item.text);
        if (args.item.action_name) {
            delete args.item.finalUpdate
            delete args.item.parentObj
            delete args.item.callChildDataBind
            delete args.item.controlParent
            if (args.item.sys_actions_types_guid === "213feb23-4db1-4a05-95db-277b265fba29") {
                changeActionButton(args.item)
            }
            else {
                switch (true) {
                    case args.item.sys_actions_types_guid === "f61dd1fe-da30-4955-bc6d-1e0be70ff257": {
                        if (args.item.action_name.toLowerCase().includes('edit')) {
                            if (selectedRow) {
                                return goToEditPage(args.item, selectedRow)
                            }
                            else {
                                return showToast('Please select a row which you want to edit!', 'danger')
                            }
                        }
                        else {
                            return goToAddPage(args.item)
                        }
                    }

                    case args.item.sys_actions_types_guid === "f543b806-b084-43df-9a8f-4743f591fa3c": {
                        dispatch({ type: 'SAVE_MANAGE_VIEWS_PANELS_GUID', payload: panel.sys_panels_guid })
                        return setFavouriteUserView()
                    }
                    case args.item.sys_actions_types_guid === "2e25e858-4479-4c9d-acd4-15485f2c131b": {
                        dispatch({ type: 'SAVE_MANAGE_VIEWS_PANELS_GUID', payload: panel.sys_panels_guid })
                        return dispatch({ type: 'TOGGLE_MANAGE_VIEWS_EDIT_BUTTON', payload: true })

                    }
                    case args.item.sys_actions_types_guid === "4a3561e4-5805-40c0-ae24-7158eee6c13b": {
                        dispatch({ type: 'SAVE_MANAGE_VIEWS_PANELS_GUID', payload: panel.sys_panels_guid })
                        return dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_BUTTON', payload: true })

                    }
                    case args.item.sys_actions_types_guid === "36f88997-33fc-44b2-8658-9d0b8680f931": {
                        dispatch({ type: 'SAVE_MANAGE_VIEWS_PANELS_GUID', payload: panel.sys_panels_guid })
                        return dispatch({ type: 'CLICK_MANAGE_VIEWS_DELETE_BUTTON', payload: true })

                    }
                    case args.item.sys_actions_types_guid === "8f86886a-c46f-4ea4-97c9-cdc844c2f3c9": {
                        openManageViewsSaveAsDialog()
                        return null

                    }
                    case args.item.sys_actions_types_guid === "825ea4c6-9206-44e7-9706-39551ad76009": {
                        dispatch({ type: 'SAVE_REFRESHED_PANELS_GUID', data: panel.sys_panels_guid })
                        return dispatch({ type: 'TOGGLE_REFRESH_BUTTON', data: !isRefreshButtonClicked })
                    }

                    case args.item.sys_actions_types_guid === "59590aa0-4dd3-4a6f-b878-8f084071384f": {
                        setMenuItemCSSId(args.item.sys_screens_panels_actions_guid)
                        const itemParts = args.item.action_value.split("~")
                        dispatch({ type: 'SET_PANELS_VIEWS_GUID', data: itemParts[0] })
                        dispatch({ type: 'SET_PANELS_GUID', data: panel.sys_panels_guid })
                        setSelectedPanelView(itemParts[0])
                        setFoundManageViewsCanEdit(JSON.parse(itemParts[1]))
                        return null
                    }


                    default:
                        return null
                }
            }

        }
    }

    const goToEditReport = () => {
        if (id) {
            navigate(`/edit-report`, { state: { screenId: id } });
        }
    }

    const generateMenuItems = (button) => {
        if (!button) {
            return [];
        }

        const generateSingleMenuItem = (btn, index) => {
            const menuItem = {
                ...btn,
                text: btn.action_name,
                iconCss: `e-icons e-${btn.icon_name || ''}`,
                items: generateMenuItems(btn.buttons),
                id: `menu-item-${btn.sys_screens_panels_actions_guid}`,
            };
            return menuItem;
        };

        if (Array.isArray(button)) {
            return button.map((btn, index) => generateSingleMenuItem(btn, index));
        } else {
            return [generateSingleMenuItem(button)];
        }
    };

    const handleClick = (event) => {
        if (anchorEl !== null) {
            dispatch({ type: 'TOGGLE_TREEVIEW_POPOVER', payload: null })
        }
        else {
            dispatch({ type: 'TOGGLE_TREEVIEW_POPOVER', payload: event.currentTarget })
        }

    };


    const open = Boolean(anchorEl);
    const popoverId = open ? 'simple-popover' : undefined;

    const deepCopy = obj => {
        return JSON.parse(JSON.stringify(obj));
    };

    const guidsToRemove = [
        "2e25e858-4479-4c9d-acd4-15485f2c131b",
        "4a3561e4-5805-40c0-ae24-7158eee6c13b",
        "a9b2cf05-aa7c-4d60-add4-2fe4a61fc510",
        "36f88997-33fc-44b2-8658-9d0b8680f931"
    ];

    function removeGuids(data) {
        if (Array.isArray(data)) {
            return data
                .map(item => removeGuids(item))
                .filter(item => !guidsToRemove.includes(item.sys_actions_types_guid));
        } else if (typeof data === 'object' && data !== null) {
            const copiedData = deepCopy(data);
            for (const key in copiedData) {
                if (Array.isArray(copiedData[key]) || typeof copiedData[key] === 'object') {
                    copiedData[key] = removeGuids(copiedData[key]);
                }
            }
            return copiedData;
        } else {
            return data;
        }
    }


    const _createToolbarMenu = () => {

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {actionButtonsArray.map((button, index) => {
                    let menuItems = generateMenuItems(button)
                    if (!foundManageViewsCanEdit) {
                        menuItems = removeGuids(menuItems);
                    }
                    if (button.action_name.toLowerCase() === 'edit polygons') {
                        return (

                            <div key={index} style={{ marginLeft: '8px' }} className={`${globalTheme?.activeTheme}`}>
                                <div className="e-menu-item" style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
                                    <ButtonComponent iconCss={`e-icons e-${button.icon_name}`} style={{ height: '32px', fontSize: '14px', backgroundColor: 'transparent' }} id={button.sys_screens_panels_actions_guid} onClick={handleClick}> {button.action_name} </ButtonComponent>
                                    <Popper
                                        id={popoverId}
                                        open={open}
                                        anchorEl={anchorEl}
                                    >
                                        <Box sx={{ border: 1, borderColor: '#C7C7C7', mr: 2, my: 0.5, bgcolor: 'background.paper' }}>
                                            <div style={{ overflow: 'hidden' }}>
                                                <TreeViewContent />
                                            </div>
                                        </Box>
                                    </Popper>
                                </div>

                            </div>
                        );
                    } else {
                        return (
                            <div className={`${globalTheme?.activeTheme}`} style={{ height: '40px', marginLeft: '8px' }} key={index}>
                                <MenuComponent items={menuItems} select={getActionButton}></MenuComponent>
                                <DialogComponent
                                    visible={isManageViewsSaveAsDialogOpen}
                                    width='250px'
                                    height='200px'
                                    header='Save view as'
                                    content={manageViewsSaveAsDialogContent}
                                    close={() => dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_AS_DIALOG', payload: false })}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        );

    }

    const _createReportToolbarMenu = () => {
        const menuItems = [{ text: 'Edit Report' }]
        return (
            <div className={`menu-container ${globalTheme?.activeTheme}`}>
                <MenuComponent items={menuItems} select={goToEditReport}></MenuComponent>
            </div>
        )
    }

    return (
        <>
            <ToastComponent ref={toastRef} />
            <div className={draggableHandleClass} style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: globalTheme[globalTheme.activeTheme]?.headerBackground, padding: '0 10px', alignItems: 'center', height: '40px', fontSize: '14px', }}>
                {panel && panel.panel_name}
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    {/* {
                        panelViewList.length > 0 ?
                            <div id='default'>
                                <DropDownListComponent id="games" dataSource={panelViewList} fields={fields} change={onChange.bind(this)} placeholder="Select a panel view" value={value} popupHeight="220px" />
                            </div>
                            :
                            null
                    } */}

                    {
                        panel?.panel_actions_json?.buttons ? _createToolbarMenu() : panel?.panel_json?.element_type === 'report' ? _createReportToolbarMenu() : null
                    }
                </div>


            </div>
        </>

    )
}

export default PanelsContent